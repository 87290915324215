<template>
  <div class="order-detail-box" v-loading="pageLoading">
    <!-- <el-button @click="handleBack" class="back-btn" size="small">返回</el-button> -->
    <!-- <div class="order-title">订单详情</div> -->
    <template v-if="orderDetailData">
      <div class="order-tips">
        {{ orderDetailData.paid | handleOrderTitle }}
      </div>
      <div class="goodsorderBox">
        <div class="orderBox">
          <div class="orderTopInfo">
            <div>
              <p class="orderP" style="font-size: 12px">
                订单号: {{ orderDetailData.orderId }}
              </p>
              <!-- <p class="order-schedule-success">完成</p> -->
              <p class="orderScheduleSuccess">
                {{ orderDetailData.paid | handleOrderStatus }}
              </p>
              <el-button
                size="small"
                style="margin-top: 20px"
                v-if="orderDetailData.paid == 0 && orderDetailData.isUse === 1"
                type="primary"
                class="opt-one-btn"
                @click.stop="goPay(orderDetailData)"
                >立即支付</el-button
              >
            </div>
          </div>
          <div class="orderBottomInfo">
            <div class="stepsInfo">
              <el-steps
                class="order-schedule-steps"
                direction="vertical"
                :active="orderDetailData.paid | handleStep"
                :width="stepWidth"
                :space="space"
                finish-status="success"
              >
                <el-step
                  title="提交订单"
                  :description="orderDetailData.createTime"
                >
                </el-step>
                <el-step title="付款成功"></el-step>
                <el-step title="成功"></el-step>
              </el-steps>
            </div>
          </div>
        </div>

        <div class="order-goods-info">
          <div class="goodsInfoLeft">
            <div class="order-goods-img">
              <!-- 图片 -->
              <!-- orderDetailData.goodsImg.split(',')[0] -->
              <div
                class="prod-info"
                style="
                  padding-top: 0.78125rem;
                  margin-bottom: 0.78125rem;
                  font-weight: bold;
                "
              >
                商品信息
              </div>
              <div style="position: relative; width: 300px; height: 150px">
                <img
                  class="img"
                  :src="orderDetailData.goodsImg || defaultImg"
                  alt=""
                  width="300px"
                  height="150px"
                  style="
                    border-radius: 12px;
                    object-fit: cover;
                    cursor: pointer;
                  "
                  @click="gotoDetail"
                />
                <div
                  v-if="orderDetailData.isUse === 0"
                  class="out-of-stock-layer"
                >
                  <svg
                    t="1700153530686"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="4296"
                    width="128"
                    height="128"
                  >
                    <path
                      d="M512 949.248c-59.024384 0-116.287488-11.561984-170.200064-34.36544-52.068352-22.023168-98.828288-53.548032-138.981376-93.70112s-71.678976-86.913024-93.702144-138.981376C86.31296 628.287488 74.750976 571.02336 74.750976 512s11.561984-116.287488 34.36544-170.200064c22.023168-52.068352 53.549056-98.828288 93.702144-138.981376s86.913024-71.678976 138.981376-93.70112C395.712512 86.313984 452.975616 74.752 512 74.752s116.287488 11.561984 170.200064 34.36544c52.068352 22.023168 98.828288 53.548032 138.981376 93.70112s71.678976 86.913024 93.702144 138.981376c22.803456 53.912576 34.36544 111.176704 34.36544 170.200064s-11.561984 116.287488-34.36544 170.200064c-22.023168 52.068352-53.549056 98.828288-93.702144 138.981376s-86.913024 71.678976-138.981376 93.70112C628.287488 937.686016 571.024384 949.248 512 949.248zM512 95.232c-56.267776 0-110.846976 11.01824-162.22208 32.74752-49.627136 20.990976-94.198784 51.042304-132.477952 89.321472-38.278144 38.278144-68.330496 82.850816-89.320448 132.477952C106.249216 401.154048 95.232 455.733248 95.232 512.001024s11.01824 110.846976 32.74752 162.22208c20.989952 49.627136 51.042304 94.198784 89.320448 132.477952 38.279168 38.278144 82.850816 68.330496 132.477952 89.321472 51.375104 21.72928 105.954304 32.74752 162.22208 32.74752s110.846976-11.01824 162.22208-32.74752c49.627136-20.990976 94.198784-51.042304 132.477952-89.321472 38.278144-38.278144 68.330496-82.850816 89.320448-132.477952C917.750784 622.848 928.768 568.2688 928.768 512.001024s-11.01824-110.846976-32.74752-162.22208c-20.989952-49.627136-51.042304-94.198784-89.320448-132.477952-38.279168-38.278144-82.850816-68.330496-132.477952-89.321472C622.846976 106.249216 568.267776 95.232 512 95.232z"
                      fill="#dbdbdb"
                      p-id="4297"
                    ></path>
                    <path
                      d="M190.129152 508.198912l0 77.984768c0 10.789888 5.395456 16.185344 16.431104 16.185344l127.276032 0c6.131712 0 11.035648-1.471488 14.46912-4.41344 5.149696-5.39648 8.338432-21.82656 9.80992-49.782784l12.996608 4.169728c-2.20672 29.673472-6.130688 48.065536-11.77088 54.93248-5.149696 5.149696-12.507136 7.847936-21.825536 7.847936L202.636288 615.122944c-17.412096 0-25.995264-8.828928-25.995264-26.48576L176.641024 458.416128l13.488128 0 0 36.785152 139.293696 0 0-68.665344L162.417664 426.535936l0-12.752896 180.492288 0 0 94.415872L190.129152 508.198912z"
                      p-id="4298"
                      fill="#dbdbdb"
                    ></path>
                    <path
                      d="M615.611392 413.784064l0 12.997632L507.462656 426.781696l0 40.953856c30.900224 15.939584 62.28992 34.332672 94.170112 55.177216l-7.847936 11.77088c-34.087936-23.051264-62.780416-40.70912-86.322176-53.215232l0 142.235648-12.996608 0L494.466048 426.781696l-95.39584 0 0-12.997632L615.611392 413.784064z"
                      p-id="4299"
                      fill="#dbdbdb"
                    ></path>
                    <path
                      d="M700.708864 400.295936l0 18.393088c0 1.471488-0.244736 3.187712-0.244736 4.6592l47.57504 0c-0.490496 40.953856-2.942976 65.477632-7.602176 73.570304-4.6592 7.847936-12.752896 12.01664-24.523776 12.01664-3.923968 0-8.338432-0.24576-13.488128-0.735232l-3.433472-12.261376c5.640192 0.490496 10.300416 0.979968 13.978624 0.979968 9.3184-0.244736 15.204352-3.678208 17.656832-10.300416 2.451456-6.62016 3.678208-23.786496 4.168704-51.499008l-35.313664 0c-3.923968 34.824192-19.37408 60.818432-46.103552 77.984768l-9.80992-8.828928c25.014272-15.205376 39.237632-38.25664 42.91584-69.15584l-38.01088 0 0-11.77088 38.992896 0c0-1.717248 0.244736-3.187712 0.244736-4.6592l0-18.393088L700.708864 400.295936zM746.812416 532.967424l0-21.579776 13.243392 0 0 21.579776 99.074048 0 0 12.507136-85.096448 0c17.166336 21.5808 46.349312 40.953856 87.548928 57.63072l-9.073664 11.281408c-42.671104-20.354048-72.834048-43.406336-90.001408-68.912128l-2.451456 0 0 77.249536-13.243392 0L746.812416 545.47456l-2.451456 0c-18.393088 28.447744-49.046528 51.744768-92.208128 69.892096l-7.35744-12.261376c40.70912-14.959616 70.137856-34.087936 88.039424-57.63072l-84.850688 0 0-12.507136L746.812416 532.967424zM848.094208 419.424256l0 80.681984-75.777024 0 0-80.681984L848.094208 419.424256zM835.587072 431.685632l-50.518016 0 0 56.159232 50.518016 0L835.587072 431.685632z"
                      p-id="4300"
                      fill="#dbdbdb"
                    ></path>
                  </svg>
                  <!-- <div class="message">
                    <p class=""></p>
                    <svg>
                    </svg>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- 商品信息 -->
            <div class="order-good-box">
              <div class="order-info">
                <div class="order-detailBox" @click="gotoDetail">
                  <div style="margin-top: 10px">
                    {{ orderDetailData.goodsName }}
                    {{ orderDetailData.timeLimitVal ? '/' : '' }}
                    {{ orderDetailData.timeLimitVal }}
                    {{
                      orderDetailData.expansionSizeVal
                        ? orderDetailData.expansionSizeVal
                        : ''
                    }}/ ¥{{ orderDetailData.originalPrice
                    }}{{ orderDetailData.productType === 3 ? `/年` : '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              width: 1px;
              height: 400px;
              background-color: #eee;
              margin: 20px 50px;
            "
          ></div>
          <div class="goodsInfoRight">
            <div class="orderInfo">
              <div class="order-info-tit">订单信息</div>
              <div class="order-txt">订单号：{{ orderDetailData.orderId }}</div>
              <div class="order-txt">
                订单时间：{{ orderDetailData.payTime }}
              </div>
              <!-- v-if="orderDetailData.productType==1" -->
              <div
                class="order-txt"
                v-if="![0, 2, 3, 4].includes(orderDetailData.paid)"
              >
                起止时间：{{
                  orderDetailData.startTime
                    ? orderDetailData.startTime.split(' ')[0]
                    : ''
                }}<span style="margin: 0 3px">至</span
                >{{
                  orderDetailData.expirationTime
                    ? orderDetailData.expirationTime.split(' ')[0]
                    : ''
                }}
              </div>
              <!--v-if="orderDetailData.productType==1" -->
              <!-- <div class="order-txt">到期时间：{{ orderDetailData.expirationTime }}</div> -->
              <!-- v-if="orderDetailData.productType==1" -->
            </div>
            <div class="payInfo">
              <div
                class="order-info-endlong"
                v-if="orderDetailData && orderDetailData.payType !== null"
              ></div>
              <div
                class="order-pay-box"
                v-if="
                  orderDetailData &&
                  orderDetailData.payType !== null &&
                  orderDetailData.paid == 1
                "
              >
                <div class="order-info-tit">支付信息</div>
                <div class="order-txt">
                  支付方式: {{ orderDetailData.payType | handlePayTiype }}
                </div>
                <div class="order-txt">
                  支付金额: ¥{{ orderDetailData.payPrice }}
                </div>
                <div class="order-txt">
                  购买人:
                  <span
                    :title="orderDetailData.brandName"
                    v-if="orderDetailData.brandName.length > 15"
                  >
                    {{ orderDetailData.brandName.slice(0, 15) }}...
                  </span>
                  <span :title="orderDetailData.brandName" v-else>
                    {{ orderDetailData.brandName }}
                  </span>
                </div>
                <div class="order-txt">
                  支付时间: {{ orderDetailData.payTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->

      <!-- <el-button class="goInfoBtn" @click="handleBack" size="small"
        >返回</el-button
      > -->
      <el-dialog
        title="选择付款方式"
        :visible.sync="choosePayType"
        :show-close="true"
        @close="closeDialog('choosePayType')"
        width="450px"
        center
      >
        <el-form :model="LoginUser" ref="ruleForm">
          <el-form-item prop="reason">
            <div style="margin-left: 20px">
              支付金额：<span
                style="font-size: 25px; color: #fa8919; margin-right: 5px"
                >¥</span
              ><span style="font-size: 50px; color: #fa8919">{{
                parseFloat(payOrderInfo.payPrice).toFixed(2)
              }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="reason">
            <div class="subItem">
              <div class="zhiFuItem">
                <div
                  :class="['change-paytype', { current: payType === 'wxpay' }]"
                  @click="selectPayType('wxpay')"
                >
                  <div class="change-paytype-icon">
                    <img src="@/assets/imgs/wxpay.png" class="paytype-icon" />
                  </div>
                  <div class="change-paytype-info">
                    <div class="change-paytype-label">微信支付</div>
                  </div>
                </div>
                <div
                  :class="['change-paytype', { current: payType === 'alipay' }]"
                  @click="selectPayType('alipay')"
                >
                  <div class="change-paytype-icon">
                    <img src="@/assets/imgs/alipay.png" class="paytype-icon" />
                  </div>
                  <div class="change-paytype-info">
                    <div class="change-paytype-label">支付宝</div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              style="
                background-color: #fa8919;
                color: #fff;
                width: 60%;
                margin-left: 20%;
              "
              @click="handleCentain()"
              >立即支付</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        :visible.sync="codeDialogVisible"
        :close-on-click-modal="false"
        @close="closeDialog('codeDialogVisible')"
        width="350px"
        center
        title="使用微信扫码支付"
      >
        <qriously :value="codeUrl" :size="300" />
      </el-dialog>
    </template>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import {
  weixinPay,
  alipayH5,
  alipayPc,
  updateUserInfo,
  queryOrderStatus,
} from '@/api/home'
import { getOrderList } from '@/api/home'
import { mapActions } from 'vuex'
export default {
  // props: {
  //   orderDetailData: {
  //     type: Object,
  //     default: () => { }
  //   },
  // },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.user.user,
    // }),
    userInfo: {
      get() {
        return this.$store.state.user.user
      },
      set(value) {
        this.$store.commit('setUser', value)
      },
    },
  },
  data() {
    return {
      orderDetailData: {},
      LoginUser: {},
      // 支付弹窗
      choosePayType: false,
      // 二维码显隐
      codeDialogVisible: false,
      // 支付订单信息
      payOrderInfo: {},
      pageLoading: false,
      defaultImg: require('@/assets/imgs/no-image.png'),
      activeStep: 3, // 当前激活的步骤索引
      space: 400, // 步骤条各步骤之间的间距
      stepWidth: '100%', // 步骤条的宽度
      payType: 'wxpay',
      codeUrl: '',
    }
  },
  created() {
    // const id = this.$route.query.orderId
    const id = window.localStorage.getItem('orderId')
    console.log('id-------', id)
    console.log(this.$route.query.orderId)
    this.getOrderDetails(id)
    // console.log('111', this.props)
    // console.log('22', this.props.orderDetailData)
  },
  filters: {
    handleStep(val) {
      let stepNum = 1
      if (val === 0) {
        stepNum = 1
      } else if (val === 1) {
        stepNum = 3
      }
      return stepNum
    },
    handleOrderStatus(value) {
      const statusMap = {
        0: '未支付',
        1: '已付款',
        3: '已关闭',
        4: '已取消',
        5: '退款中',
        6: '已退款',
        7: '退款失败',
      }
      return statusMap[value]
    },
    handleOrderTitle(value) {
      // 订单已完成支付，感谢您在捷盈网站平台入驻下单！！！
      const statusTitle = {
        0: '订单未支付,请继续支付订单',
        1: '订单已完成支付，感谢您在捷盈企业网站-入驻商城下单！！！',
        3: '订单已关闭',
        4: '订单已取消,请重新选择商品',
        5: '订单退款中',
        6: '订单已退款',
        7: '订单退款失败',
      }
      return statusTitle[value]
    },
    handleCulateTime(endDate) {
      if (!endDate) {
        return
      }
      // 计算相隔天数
      const start = dayjs(dayjs().format('YYYY-MM-DD'))
      const end = dayjs(endDate)
      const diffInDays = end.diff(start, 'day')

      // 转换为「n年n月n天」格式
      const years = Math.floor(diffInDays / 365)
      const months = Math.floor((diffInDays % 365) / 30)
      const days = diffInDays % 30
      return `${years}年${months}月${days}天`
    },
    calculateValidityPeriod(payTime, expirationTime) {
      const payDate = new Date(payTime)
      const expirationDate = new Date(expirationTime)

      const timeDiff = expirationDate.getTime() - payDate.getTime()
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

      const years = Math.floor(daysDiff / 365)
      const months = Math.floor((daysDiff % 365) / 30)
      const days = daysDiff % 30

      let validityPeriod = ''
      if (years > 0) {
        validityPeriod += `${years}年`
      }
      if (months > 0) {
        validityPeriod += `${months}个月`
      }
      if (days > 0) {
        validityPeriod += `${days}天`
      }

      return validityPeriod
    },
    handlePayTiype(type) {
      if (type || type == 0) {
        const payType = {
          0: '微信支付',
          1: '支付宝支付',
        }
        return payType[type]
      }
    },
  },
  watch: {},
  methods: {
    ...mapActions(['setUser']),
    calculateValidity(orderEndTime, orderStartTime) {
      const endTime = new Date(orderEndTime)
      const startTime = new Date(orderStartTime)

      const diffTime = endTime.getTime() - startTime.getTime()

      const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))
      const diffMonths = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)
      )
      const diffDays = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
      )

      return `${diffYears}年${diffMonths}月${diffDays}日`
    },
    // async getOrderDetails (id = '1725118482716168192') {
    async getOrderDetails(id) {
      if (!id) return
      const phone = this.userInfo.mobile
      const params = { phoneNumber: phone, page: 1, limit: 10, orderId: id }
      const { data } = await getOrderList(params)
      console.log(data.data.list[0])
      this.orderDetailData = data.data.list[0]
    },
    gotoDetail() {
      console.log('orderDetailData', this.orderDetailData)
      const { isUse, policyId, seckillId, productType } = this.orderDetailData

      // 下架商品无法跳转商品详情
      if (isUse === 0 || productType === 2 || productType === 3) {
        const msg =
          isUse === 0
            ? '商品已下架，无法查看商品详情！'
            : productType === 2
            ? '当前订单为内存扩容订单，无法查看商品详情！'
            : '当前订单为升级订单，无法查看商品详情！'
        this.$message.warning(msg)
        return
      }
      const routeConfig = {
        0: {
          name: 'Details',
          query: { productID: policyId },
        },
        1: {
          name: 'SeckillDetails',
          query: { productID: seckillId },
        },

        default: {
          name: 'internalStorageDetails',
          query: {},
        },
      }

      const route = routeConfig[productType] || routeConfig.default
      this.$router.push({
        name: route.name,
        query: route.query,
      })
    },
    selectPayType(type) {
      this.payType = type
    },
    closeDialog(type) {
      // console.log("close.................");
      this[type] = false
      // console.log("清除定时器");
      clearInterval(this.timer)
    },
    handleBack() {
      this.$router.go(-1)
      // this.$emit('updateIsView', false)
    },
    goPay(row) {
      this.choosePayType = true
      this.payOrderInfo = row
    },
    isWX() {
      var wx = window.navigator.userAgent.toLowerCase()
      console.log(wx)
      if (wx.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    async handleCentain() {
      // console.log(this.payOrderInfo);
      this.payBtnDisabled = true
      if (this.payType === 'wxpay') {
        const params = {
          orderId: this.payOrderInfo.orderId,
          goodsId: this.payOrderInfo.goodsId,
          phoneNumber: this.$store.getters.getUser.mobile,
          payType: '0',
          sellType: this.payOrderInfo.productType,
          mobilePay: this.$device.mobile ? 1 : 0,
          seckillId: this.payOrderInfo.seckillId
            ? this.payOrderInfo.seckillId
            : '',
          // mobilePay: this.isWX()==true ? 2 : this.$device.mobile ? 1 : 0
        }
        const res = await weixinPay(params)
        if (res.code == 0) {
          console.log(res)
          if (this.$device.mobile) {
            //在跳转支付链接后，拼接微信回跳地址
            location.href =
              res.data.codeUrl +
              '&redirect_url=' +
              encodeURIComponent(window.location.href)
          } else {
            this.codeUrl = res.data.codeUrl
            //打开二维码弹窗
            this.codeDialogVisible = true
          }
          this.orderId = res.data.orderId
          //启动定时器
          this.timer = setInterval(() => {
            //查询订单是否支付成功
            this.queryOrderStatus(this.orderId)
          }, 3000)
        }
      }
      if (this.payType === 'alipay') {
        const params = {
          orderId: this.payOrderInfo.orderId,
          goodsId: this.payOrderInfo.goodsId,
          phoneNumber: this.$store.getters.getUser.mobile,
          sellType: this.payOrderInfo.productType,
          payType: '1',
          seckillId: this.payOrderInfo.seckillId
            ? this.payOrderInfo.seckillId
            : '',
        }
        if (this.$device.mobile) {
          const res = await alipayH5(params)
          if (res.code === 0) {
            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.data.formStr
            document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit
            document.querySelector('#alipay form').submit()

            // 清除添加的 div 元素
            document.body.removeChild(div)
          } else {
            this.payBtnDisabled = false
          }
        } else {
          const res = await alipayPc(params)
          if (res.code === 0) {
            // document.write(res.data.formStr);

            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.data.formStr
            document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit
            document.querySelector('#alipay form').submit()

            // 清除添加的 div 元素
            document.body.removeChild(div)
          } else {
            // this.notifyWarning(res.msg);
            this.payBtnDisabled = false
          }
        }
      }
    },
    // 查询订单状态
    async queryOrderStatus(orderId) {
      const res = await queryOrderStatus(orderId)
      if (res.code == 0) {
        clearInterval(this.timer)
        this.codeDialogVisible = false
        this.choosePayType = false
        this.notifySucceed('支付成功')
        this.updateUserInfo()
        setTimeout(() => {
          //刷新页面
          location.reload()
        }, 1000)
      }
    },
    //  更新用户信息
    async updateUserInfo() {
      const phone = this.userInfo.mobile
      const res = await updateUserInfo(phone)
      if (res.code == 0) {
        const userInfo = res.data.loginUser
        this.userInfo = userInfo
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.stepsInfo {
  margin-left: 80px;
}
.goodsorderBox {
  display: flex;
}
// 支付进度表
.orderBox {
  width: 30%;
  height: 600px;

  // background-color: red;
  .orderTopInfo {
    width: 100%;
    text-align: center;
    height: 200px;

    // background-color: blue;
    .orderP {
      margin-bottom: 15px;
      padding-top: 40px;
    }

    .orderScheduleSuccess {
      color: #71b247 !important;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      margin-top: 15px;
      // opacity:0.5
    }
  }

  .orderBottomInfo {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    // background-color: pink;
  }
}

// 支付进度表
// 遮罩层
.out-of-stock-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  // background-color: red;
  // display: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message {
  // background-color: #fff;
  color: #fff;
  // padding: 20px;

  line-height: 150px;
  border-radius: 5px;
  text-align: center;
}

// .close-btn {
//   margin-top: 10px;
//   background-color: #333;
//   color: #fff;
//   border: none;
//   border-radius: 3px;
//   padding: 5px 10px;
//   cursor: pointer;
// }
// 遮罩层
// 底部订单详情
.goodsInfoLeft {
  width: 350px;
  height: 420px;
  text-align: left;
  // background-color: pink;
}

.goodsInfoRight {
  width: 420px;
  height: 500px;
  margin: 0 15px;

  // background-color: blue;
  .orderInfo .order-info-tit {
    padding-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .order-txt {
    margin-bottom: 10px;
  }
}

.goodsInfoRight .orderInfo {
  width: 100%;
  text-align: left;
  // background-color: red;
}

.goodsInfoRight .payInfo {
  width: 100%;
  height: 50%;
  text-align: left;

  // background-color: brown;
  .order-info-tit {
    padding-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .order-txt {
    margin-bottom: 10px;
  }
}

// 底部订单详情
.goInfoBtn {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.order-detailBox:hover {
  cursor: pointer;
}
.order-detail-box {
  margin: 0 auto;
  padding: 40px;
  padding-top: 0;
  min-height: 67vh;
  background-color: #fff;
  border-radius: 12px;
  .order-schedule-box {
    // width: 1167px;
    // height: 190px;
    // background: #e5e5e5;
    background: rgba(229, 229, 229, 0.3);
    // opacity: 0.3;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    .order-schedule-endlong {
      width: 1px;
      height: 138px;
      background: #696868;
      margin-top: 30px;
    }
    .order-schedule-right {
      width: 100%;
      margin: 0 auto;
      .order-schedule-steps {
        margin-left: 120px;
        margin-top: 50px;
      }
    }
    .order-schedule-left {
      width: 25%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-right: 30px;
      .order-schedule-success {
        color: #71b247 !important;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        margin-top: 15px;
        // opacity:0.5
      }
      div {
        margin: 80px auto;
      }
    }
  }
  .order-title {
    color: #333333;
    font-size: 24px;
    margin-bottom: 24px;
  }
  .back-btn {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .order-tips {
    /* padding-top: 50px; */
    /* margin: 50px; */
    margin-left: 12px;
    height: 40px;
    margin-bottom: 10px;
  }
  .order-goods-img {
    // position: relative;
    // padding-top: 15px;
  }
  .order-goods-info {
    width: 70%;
    display: flex;
    padding-top: 40px;
    // flex-direction: row;
    justify-content: center;
  }
  .order-good-box {
    // margin-left: 50px;
    // display: flex;
    // flex-direction: row;
    .order-info-endlong {
      width: 1px;
      height: 200px;
      background: #bebebe;
      margin-left: 52px;
      margin-right: 23px;
    }

    .order-info-tit {
      padding-top: 3.82rem;
      margin-bottom: 15px;
      font-weight: bold;
    }

    .order-txt {
      margin-bottom: 10px;
      // margin-left: 40px;
    }
  }
}

.subItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  // background-color: red;
  .itemTitle {
    width: 15%;
    font-size: 16px;
    color: #666;
  }
  .itemContent {
    width: 80%;
  }

  ul {
    display: flex;
    align-items: s;
    flex-wrap: wrap;
    width: 100%;
    li {
      font-size: 18px;
      padding: 5px 20px;
      border-radius: 5px;
      border: 1px solid #e7ecf0;
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      border: 1px solid #fa8919 !important;
      color: #fa8919;
    }
    .selectActive {
      border: 1px solid #fa8919 !important;
      color: #fa8919;
    }
  }
  // 选择支付方式
  .zhiFuItem {
    display: flex;
    /* 选择支付方式 */
    .change-paytype {
      position: relative;
      display: flex;
      align-items: center;
      width: 207px;
      height: 58px;
      margin-right: 10px;
      border: 1px solid #e7ecf0;
      border-radius: 4px;
      cursor: pointer;
    }

    .change-paytype.current {
      border-color: #fa8919;
      color: #fa8919;
    }

    .change-paytype.current:after {
      content: '';
      display: block;
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
        no-repeat;
      background-size: 28px 28px;
    }

    .change-paytype-icon {
      margin: 0 11px 0 15px;
    }

    .paytype-icon {
      display: block;
      width: 24px;
      height: 24px;
    }

    .change-paytype-info {
      position: relative;
      flex: 1;
    }
    .change-paytype:hover {
      border-color: #fa8919;
    }
    .change-paytype-label:hover {
      color: #fa8919;
    }
  }
  // 价格
  .price-info {
    width: 80%;
    display: flex;
    align-items: self-end;
    flex-wrap: wrap;
    color: #ff4302;
    margin-top: 20px;
    .sale-price {
      display: flex;
      align-items: self-end;
    }
    .price-icon {
      font-size: 30px;
      font-weight: 400;
      margin-right: 10px;
    }
    .price-val {
      font-size: 45px;
      font-weight: bold;
    }
    .price-old {
      color: #a1a1a1;
      font-size: 25px;
      margin-left: 20px;
    }
  }
}

// .order-goods-info {
//   display: flex;
// }

// .order-good-box {
//   margin-left: 50px;
// }
// .order-info-tit {
//   margin-bottom: 20px;
//   font-weight: bold;
// }
// .order-txt {
//   margin-bottom: 15px;
//   margin-left: 40px;
// }
// .back-btn {
//   margin-bottom: 15px;
// }
</style>

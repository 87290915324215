<!-- 分页组件 -->
<template>
  <el-pagination
    :align="align"
    :style="{'margin-top':marginTop}"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="page.currentPage"
    :page-sizes="page.pageSizeArea"
    :page-size="page.limit"
    layout="total, sizes, prev, pager, next, jumper"
    :total="page.total">
  </el-pagination>
</template>

<script>
export default {
  props: {
    // 对齐方式
    align: {
      type: String,
      default: 'center'
    },
    // 上边距
    marginTop: {
      type: String,
      default: '15px'
    },
    // 分页信息
    page: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
    }
  },
  methods: {
    // 分页条数变化
    handleSizeChange(val) {
      this.$emit('size-change',val)
    },
    // 页码变化
    handleCurrentChange(val) {
      this.$emit('current-change',val)
    },
  },
}
</script>

<style scoped>
</style>
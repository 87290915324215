<!--
* @Description: 用户中心
* @Author: xxxxx
* @Date: 2020-3-27 16:18:25
* @LastEditors: xxxxx
* @LastEditTime: 2020-3-27 16:18:25
-->
<template>
  <div class="page-box" v-loading="isLoading">
    <div class="back-btn" v-if="!isView">
      <img
        src="../../assets/imgs/back-btn.png"
        @click="$router.back()"
        alt=""
      />
      <span class="user-tit">我的订单</span>
    </div>
    <div class="order-infobox" v-if="!isView">
      <el-card class="box-card">
        <!-- <div slot="header" class="clearfix">
          <span style="font-size: 24px">我的订单2</span>
        </div> -->
        <el-form :inline="true" :model="queryParams" class="demo-form-inline">
          <el-form-item label="商品名称">
            <el-input
              size="small"
              v-model.trim="queryParams.goodsName"
              placeholder="商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单编号">
            <el-input
              size="small"
              v-model.trim="queryParams.orderId"
              placeholder="订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单状态">
            <!-- <el-input size="small" v-model="queryParams.paid" placeholder="订单状态"></el-input> -->
            <el-select
              v-model="queryParams.orderType"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in payStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单日期">
            <el-date-picker
              v-model="queryParams.betweenTime"
              type="daterange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="handleQueryOrder"
              >查询</el-button
            >
            <el-button size="small" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-tabs
          v-model="activeName"
          type="card"
          active-text-color="#fa8919"
          @tab-click="handleClick"
        >
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="未支付" name="0"></el-tab-pane>
          <el-tab-pane label="已付款" name="1"></el-tab-pane>
          <el-tab-pane label="已完成" name="8"></el-tab-pane>
          <el-tab-pane label="已取消" name="4"></el-tab-pane>
        </el-tabs>
        <el-table :data="orders" class="order-table">
          <el-table-column
            prop="orderId"
            label="订单编号"
            align="center"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="address"
            width="300px"
            label="商品信息"
            align="center"
          >
            <template slot-scope="{ row }">
              <!-- <div style="position:relative;width:200px;height:150px"> -->
              <div @click="handleLinkDetail(row)" style="cursor: pointer">
                <div class="positImg">
                  <div
                    style="
                      position: relative;
                      width: 150px;
                      height: 100px;
                      margin-bottom: 10px;
                    "
                  >
                    <img
                      :src="row.goodsImg"
                      alt=""
                      class="order-goodimg"
                    /><br />
                    <div v-if="row.isUse === 0" class="out-of-stock-layer">
                      <svg
                        t="1700474504289"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1957"
                        id="mx_n_1700474504291"
                        width="80"
                        height="80"
                      >
                        <path
                          d="M512 949.248c-59.024384 0-116.287488-11.561984-170.200064-34.36544-52.068352-22.023168-98.828288-53.548032-138.981376-93.70112s-71.678976-86.913024-93.702144-138.981376C86.31296 628.287488 74.750976 571.02336 74.750976 512s11.561984-116.287488 34.36544-170.200064c22.023168-52.068352 53.549056-98.828288 93.702144-138.981376s86.913024-71.678976 138.981376-93.70112C395.712512 86.313984 452.975616 74.752 512 74.752s116.287488 11.561984 170.200064 34.36544c52.068352 22.023168 98.828288 53.548032 138.981376 93.70112s71.678976 86.913024 93.702144 138.981376c22.803456 53.912576 34.36544 111.176704 34.36544 170.200064s-11.561984 116.287488-34.36544 170.200064c-22.023168 52.068352-53.549056 98.828288-93.702144 138.981376s-86.913024 71.678976-138.981376 93.70112C628.287488 937.686016 571.024384 949.248 512 949.248zM512 95.232c-56.267776 0-110.846976 11.01824-162.22208 32.74752-49.627136 20.990976-94.198784 51.042304-132.477952 89.321472-38.278144 38.278144-68.330496 82.850816-89.320448 132.477952C106.249216 401.154048 95.232 455.733248 95.232 512.001024s11.01824 110.846976 32.74752 162.22208c20.989952 49.627136 51.042304 94.198784 89.320448 132.477952 38.279168 38.278144 82.850816 68.330496 132.477952 89.321472 51.375104 21.72928 105.954304 32.74752 162.22208 32.74752s110.846976-11.01824 162.22208-32.74752c49.627136-20.990976 94.198784-51.042304 132.477952-89.321472 38.278144-38.278144 68.330496-82.850816 89.320448-132.477952C917.750784 622.848 928.768 568.2688 928.768 512.001024s-11.01824-110.846976-32.74752-162.22208c-20.989952-49.627136-51.042304-94.198784-89.320448-132.477952-38.279168-38.278144-82.850816-68.330496-132.477952-89.321472C622.846976 106.249216 568.267776 95.232 512 95.232z"
                          fill="#e6e6e6"
                          p-id="1958"
                        ></path>
                        <path
                          d="M190.129152 508.198912l0 77.984768c0 10.789888 5.395456 16.185344 16.431104 16.185344l127.276032 0c6.131712 0 11.035648-1.471488 14.46912-4.41344 5.149696-5.39648 8.338432-21.82656 9.80992-49.782784l12.996608 4.169728c-2.20672 29.673472-6.130688 48.065536-11.77088 54.93248-5.149696 5.149696-12.507136 7.847936-21.825536 7.847936L202.636288 615.122944c-17.412096 0-25.995264-8.828928-25.995264-26.48576L176.641024 458.416128l13.488128 0 0 36.785152 139.293696 0 0-68.665344L162.417664 426.535936l0-12.752896 180.492288 0 0 94.415872L190.129152 508.198912z"
                          p-id="1959"
                          fill="#e6e6e6"
                        ></path>
                        <path
                          d="M615.611392 413.784064l0 12.997632L507.462656 426.781696l0 40.953856c30.900224 15.939584 62.28992 34.332672 94.170112 55.177216l-7.847936 11.77088c-34.087936-23.051264-62.780416-40.70912-86.322176-53.215232l0 142.235648-12.996608 0L494.466048 426.781696l-95.39584 0 0-12.997632L615.611392 413.784064z"
                          p-id="1960"
                          fill="#e6e6e6"
                        ></path>
                        <path
                          d="M700.708864 400.295936l0 18.393088c0 1.471488-0.244736 3.187712-0.244736 4.6592l47.57504 0c-0.490496 40.953856-2.942976 65.477632-7.602176 73.570304-4.6592 7.847936-12.752896 12.01664-24.523776 12.01664-3.923968 0-8.338432-0.24576-13.488128-0.735232l-3.433472-12.261376c5.640192 0.490496 10.300416 0.979968 13.978624 0.979968 9.3184-0.244736 15.204352-3.678208 17.656832-10.300416 2.451456-6.62016 3.678208-23.786496 4.168704-51.499008l-35.313664 0c-3.923968 34.824192-19.37408 60.818432-46.103552 77.984768l-9.80992-8.828928c25.014272-15.205376 39.237632-38.25664 42.91584-69.15584l-38.01088 0 0-11.77088 38.992896 0c0-1.717248 0.244736-3.187712 0.244736-4.6592l0-18.393088L700.708864 400.295936zM746.812416 532.967424l0-21.579776 13.243392 0 0 21.579776 99.074048 0 0 12.507136-85.096448 0c17.166336 21.5808 46.349312 40.953856 87.548928 57.63072l-9.073664 11.281408c-42.671104-20.354048-72.834048-43.406336-90.001408-68.912128l-2.451456 0 0 77.249536-13.243392 0L746.812416 545.47456l-2.451456 0c-18.393088 28.447744-49.046528 51.744768-92.208128 69.892096l-7.35744-12.261376c40.70912-14.959616 70.137856-34.087936 88.039424-57.63072l-84.850688 0 0-12.507136L746.812416 532.967424zM848.094208 419.424256l0 80.681984-75.777024 0 0-80.681984L848.094208 419.424256zM835.587072 431.685632l-50.518016 0 0 56.159232 50.518016 0L835.587072 431.685632z"
                          p-id="1961"
                          fill="#e6e6e6"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                {{ row.goodsName }} {{ row.timeLimitVal ? '/' : '' }}
                {{ row.timeLimitVal }}
                {{ row.expansionSizeVal ? row.expansionSizeVal : '' }}/ ¥{{
                  row.originalPrice
                }}{{ row.productType === 3 ? `/年` : '' }}
              </div>
              <!-- </div> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="expirationTime"
            width="280px"
            label="起止时间"
            align="center"
          >
            <template slot-scope="{ row }">
              <div v-if="![0, 2, 3, 4].includes(row.paid)">
                {{ row.startTime ? row.startTime.split(' ')[0] : ''
                }}<br /><span>至</span><br />
                {{ row.expirationTime ? row.expirationTime.split(' ')[0] : '' }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="originalPrice" label="原价" align="center">
            <template slot-scope="{ row }"> ¥{{ row.originalPrice }} </template>
          </el-table-column> -->
          <el-table-column prop="payPrice" label="支付金额" align="center">
            <template slot-scope="{ row }"> ¥{{ row.payPrice }} </template>
          </el-table-column>
          <el-table-column prop="payTime" label="订单状态" align="center">
            <template slot-scope="{ row }">
              <div style="color: #fa8919">
                <span v-if="row.paid == 0">未支付</span>
                <span v-if="row.paid == 1">已付款</span>
                <span v-if="row.paid == 4">已取消</span>
                <span v-if="row.paid == 5">退款中</span>
                <span v-if="row.paid == 6">退款成功</span>
                <span v-if="row.paid == 7">退款失败</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="订单时间"
            width="200"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.createTime | dateFormat }}
            </template>
          </el-table-column>

          <!-- <el-table-column prop="payTime" width="120px" label="开始时间">
            <template slot-scope="{ row }">
              {{  row.payTime.split(" ")[0] }}
            </template>
          </el-table-column> -->

          <el-table-column
            width="300px"
            label="操作"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <div>
                <!-- <div v-if="handleTimeShow(scope.row.createTime)">
                    <span v-countdown="scope.row.createTime"></span>
                </div> -->
                <div v-if="scope.row.paid === 0 && scope.row.isUse === 1">
                  <div class="swatch-box">
                    <div style="width: 19px; margin-top: 2px">
                      <img
                        src="../../../src/assets/imgs/timer.png"
                        alt=""
                        style="width: 100%"
                      />
                    </div>
                    <el-statistic
                      :value="countdownValue(scope.row.createTime)"
                      @finish="hilarity(scope.row)"
                      time-indices
                      :value-style="{
                        color: 'red',
                        left: '20px',
                        width: 'auto',
                        lineHeight: '35px',
                      }"
                      format="mm分钟ss秒"
                    >
                      <template slot="suffix"> </template>
                    </el-statistic>
                  </div>
                </div>

                <el-button
                  :size="$device.mobile ? 'mini' : 'small'"
                  class="opt-one-btn"
                  @click.stop="checkOrderDetail(scope.row)"
                  >查看订单</el-button
                >
                <el-button
                  :size="$device.mobile ? 'mini' : 'small'"
                  class="opt-one-btn"
                  @click.stop="checkresetDetail(scope.row)"
                  v-if="
                    scope.row.paid === 1 &&
                    scope.row.goodsAuth !== '1,2,3' &&
                    scope.row.goodsAuth !== '1,3,2' &&
                    (scope.row.productType === 1 ||
                      scope.row.productType === 0) &&
                    scope.row.isUse === 1
                  "
                  >套餐升级
                </el-button>

                <el-button
                  :size="$device.mobile ? 'mini' : 'small'"
                  v-if="scope.row.paid == 0 && scope.row.isUse === 1"
                  type="primary"
                  class="opt-one-btn"
                  @click.stop="goPay(scope.row)"
                  >立即支付</el-button
                >
                <el-button
                  :size="$device.mobile ? 'mini' : 'small'"
                  class="opt-one-btn"
                  @click.stop="internalStorageDetailsClick(scope.row)"
                  v-show="
                    scope.row.paid == 1 &&
                    (scope.row.productType === 1 ||
                      scope.row.productType === 0) &&
                    scope.row.isUse === 1
                  "
                  >空间升级</el-button
                >
                <el-button
                  :size="$device.mobile ? 'mini' : 'small'"
                  class="opt-one-btn"
                  @click.stop="cancellationClick(scope.row)"
                  v-show="scope.row.paid === 0 && scope.row.isUse === 1"
                  >取消订单</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :page="pageInfo"
          align="right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-card>
    </div>
    <OrderDetails
      :isView="isView"
      :orderDetailData="orderDetailData"
      @updateIsView="updateIsView"
      v-else
    />
    <div class="app-order-info" v-if="!isView">
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <el-form-item label="商品名称">
          <el-input
            size="small"
            v-model.trim="queryParams.goodsName"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input
            size="small"
            v-model.trim="queryParams.orderId"
            placeholder="订单编号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="订单日期">
            <el-date-picker v-model="queryParams.betweenTime" type="daterange" size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item> -->
        <el-form-item>
          <el-button type="primary" size="small" @click="handleQueryOrder"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="app-goodorder-box">
        <el-tabs
          v-model="activeName"
          type="card"
          active-text-color="#fa8919"
          @tab-click="handleClick"
        >
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="未支付" name="0"></el-tab-pane>
          <el-tab-pane label="已付款" name="1"></el-tab-pane>
          <el-tab-pane label="已完成" name="8"></el-tab-pane>
          <el-tab-pane label="已取消" name="4"></el-tab-pane>
        </el-tabs>
        <template v-if="orders && orders.length > 0">
          <div
            class="app-goodorder-item"
            v-for="item in orders"
            :key="item.orderId"
          >
            <div class="orderid-box">
              <span>订单号：{{ item.orderId }}</span>
              <span class="order-status">{{
                item.paid | handleOrderStatus
              }}</span>
            </div>
            <div class="app-good-info" @click="checkOrderDetail(item, 'h5')">
              <div style="position: relative; width: 130px; height: 100px">
                <img :src="item.goodsImg" alt="" class="app-good-img" />
                <div v-if="item.isUse === 0" class="out-of-stock-layer">
                  <svg
                    t="1700474504289"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1957"
                    id="mx_n_1700474504291"
                    width="64"
                    height="64"
                  >
                    <path
                      d="M512 949.248c-59.024384 0-116.287488-11.561984-170.200064-34.36544-52.068352-22.023168-98.828288-53.548032-138.981376-93.70112s-71.678976-86.913024-93.702144-138.981376C86.31296 628.287488 74.750976 571.02336 74.750976 512s11.561984-116.287488 34.36544-170.200064c22.023168-52.068352 53.549056-98.828288 93.702144-138.981376s86.913024-71.678976 138.981376-93.70112C395.712512 86.313984 452.975616 74.752 512 74.752s116.287488 11.561984 170.200064 34.36544c52.068352 22.023168 98.828288 53.548032 138.981376 93.70112s71.678976 86.913024 93.702144 138.981376c22.803456 53.912576 34.36544 111.176704 34.36544 170.200064s-11.561984 116.287488-34.36544 170.200064c-22.023168 52.068352-53.549056 98.828288-93.702144 138.981376s-86.913024 71.678976-138.981376 93.70112C628.287488 937.686016 571.024384 949.248 512 949.248zM512 95.232c-56.267776 0-110.846976 11.01824-162.22208 32.74752-49.627136 20.990976-94.198784 51.042304-132.477952 89.321472-38.278144 38.278144-68.330496 82.850816-89.320448 132.477952C106.249216 401.154048 95.232 455.733248 95.232 512.001024s11.01824 110.846976 32.74752 162.22208c20.989952 49.627136 51.042304 94.198784 89.320448 132.477952 38.279168 38.278144 82.850816 68.330496 132.477952 89.321472 51.375104 21.72928 105.954304 32.74752 162.22208 32.74752s110.846976-11.01824 162.22208-32.74752c49.627136-20.990976 94.198784-51.042304 132.477952-89.321472 38.278144-38.278144 68.330496-82.850816 89.320448-132.477952C917.750784 622.848 928.768 568.2688 928.768 512.001024s-11.01824-110.846976-32.74752-162.22208c-20.989952-49.627136-51.042304-94.198784-89.320448-132.477952-38.279168-38.278144-82.850816-68.330496-132.477952-89.321472C622.846976 106.249216 568.267776 95.232 512 95.232z"
                      fill="#e6e6e6"
                      p-id="1958"
                    ></path>
                    <path
                      d="M190.129152 508.198912l0 77.984768c0 10.789888 5.395456 16.185344 16.431104 16.185344l127.276032 0c6.131712 0 11.035648-1.471488 14.46912-4.41344 5.149696-5.39648 8.338432-21.82656 9.80992-49.782784l12.996608 4.169728c-2.20672 29.673472-6.130688 48.065536-11.77088 54.93248-5.149696 5.149696-12.507136 7.847936-21.825536 7.847936L202.636288 615.122944c-17.412096 0-25.995264-8.828928-25.995264-26.48576L176.641024 458.416128l13.488128 0 0 36.785152 139.293696 0 0-68.665344L162.417664 426.535936l0-12.752896 180.492288 0 0 94.415872L190.129152 508.198912z"
                      p-id="1959"
                      fill="#e6e6e6"
                    ></path>
                    <path
                      d="M615.611392 413.784064l0 12.997632L507.462656 426.781696l0 40.953856c30.900224 15.939584 62.28992 34.332672 94.170112 55.177216l-7.847936 11.77088c-34.087936-23.051264-62.780416-40.70912-86.322176-53.215232l0 142.235648-12.996608 0L494.466048 426.781696l-95.39584 0 0-12.997632L615.611392 413.784064z"
                      p-id="1960"
                      fill="#e6e6e6"
                    ></path>
                    <path
                      d="M700.708864 400.295936l0 18.393088c0 1.471488-0.244736 3.187712-0.244736 4.6592l47.57504 0c-0.490496 40.953856-2.942976 65.477632-7.602176 73.570304-4.6592 7.847936-12.752896 12.01664-24.523776 12.01664-3.923968 0-8.338432-0.24576-13.488128-0.735232l-3.433472-12.261376c5.640192 0.490496 10.300416 0.979968 13.978624 0.979968 9.3184-0.244736 15.204352-3.678208 17.656832-10.300416 2.451456-6.62016 3.678208-23.786496 4.168704-51.499008l-35.313664 0c-3.923968 34.824192-19.37408 60.818432-46.103552 77.984768l-9.80992-8.828928c25.014272-15.205376 39.237632-38.25664 42.91584-69.15584l-38.01088 0 0-11.77088 38.992896 0c0-1.717248 0.244736-3.187712 0.244736-4.6592l0-18.393088L700.708864 400.295936zM746.812416 532.967424l0-21.579776 13.243392 0 0 21.579776 99.074048 0 0 12.507136-85.096448 0c17.166336 21.5808 46.349312 40.953856 87.548928 57.63072l-9.073664 11.281408c-42.671104-20.354048-72.834048-43.406336-90.001408-68.912128l-2.451456 0 0 77.249536-13.243392 0L746.812416 545.47456l-2.451456 0c-18.393088 28.447744-49.046528 51.744768-92.208128 69.892096l-7.35744-12.261376c40.70912-14.959616 70.137856-34.087936 88.039424-57.63072l-84.850688 0 0-12.507136L746.812416 532.967424zM848.094208 419.424256l0 80.681984-75.777024 0 0-80.681984L848.094208 419.424256zM835.587072 431.685632l-50.518016 0 0 56.159232 50.518016 0L835.587072 431.685632z"
                      p-id="1961"
                      fill="#e6e6e6"
                    ></path>
                  </svg>
                  <!-- <div class="message">
                    <p class=""></p>
                    <svg>
                    </svg>
                  </div> -->
                </div>
              </div>
              <div class="good-txt-box">
                <div class="good-name">
                  {{ item.goodsName }} {{ item.timeLimitVal ? '/' : '' }}
                  {{ item.timeLimitVal }}
                  {{ item.expansionSizeVal ? item.expansionSizeVal : '' }}/ ¥{{
                    item.originalPrice
                  }}{{ item.productType === 3 ? `/年` : '' }}
                </div>
                <div class="good-payprice">
                  <span class="price-icon">¥</span>{{ item.payPrice }}
                </div>
                <del class="good-original-price">¥{{ item.originalPrice }}</del>
                <!-- <div class="order-status">{{ item.paid | handleOrderStatus }}</div> -->
                <div v-if="item.paid === 0">
                  <div class="swatch-box">
                    <div style="width: 19px; margin-top: 2px">
                      <img
                        src="../../../src/assets/imgs/timer.png"
                        alt=""
                        style="width: 100%"
                      />
                    </div>
                    <el-statistic
                      :value="countdownValue(item.createTime)"
                      @finish="hilarity(item)"
                      time-indices
                      :value-style="{
                        color: 'red',
                        width: '100%',
                        lineHeight: '35px',
                        textAlign: 'start',
                      }"
                      format="mm分钟ss秒"
                    >
                      <template slot="suffix"> </template>
                    </el-statistic>
                  </div>
                </div>
              </div>
            </div>
            <div class="app-good-opt">
              <el-button
                :size="$device.mobile ? 'mini' : 'small'"
                class="btn-radius"
                type="primary"
                @click.stop="checkOrderDetail(item, 'h5')"
                >查看订单</el-button
              >
              <el-button
                :size="$device.mobile ? 'mini' : 'small'"
                class="opt-one-btn"
                @click.stop="checkresetDetail(item)"
                v-if="
                  item.paid === 1 &&
                  (item.productType === 1 || item.productType === 0) &&
                  item.goodsAuth !== '1,2,3' &&
                  item.goodsAuth !== '1,3,2' &&
                  item.isUse === 1
                "
                >套餐升级</el-button
              >
              <el-button
                :size="$device.mobile ? 'mini' : 'small'"
                class="opt-one-btn"
                @click.stop="internalStorageDetailsClick(item)"
                v-show="
                  item.paid == 1 &&
                  (item.productType === 1 || item.productType === 0) &&
                  item.isUse === 1
                "
                >空间升级</el-button
              >
              <el-button
                :size="$device.mobile ? 'mini' : 'small'"
                class="opt-one-btn"
                @click.stop="cancellationClick(item)"
                v-show="item.paid === 0 && item.isUse === 1"
                >取消订单</el-button
              >

              <el-button
                :size="$device.mobile ? 'mini' : 'small'"
                class="btn-radius"
                v-if="item.paid == 0 && item.isUse === 1"
                type="primary"
                @click.stop="goPay(item)"
                >去支付</el-button
              >
              <!-- <el-button size="small" type="primary" @click.stop="xufeiDetailsClick(item)">续费</el-button>
              <el-button size="small" v-if="item.paid==1" @click.stop="refundClick(item)">退款</el-button> -->
            </div>
          </div>
        </template>
        <div
          style="text-align: center; margin-top: 20px; padding-bottom: 45px"
          v-else
        >
          暂无数据
        </div>
      </div>
    </div>
    <el-dialog
      title="选择付款方式"
      :visible.sync="choosePayType"
      :show-close="true"
      @close="closeDialog('choosePayType')"
      center
    >
      <el-form :model="LoginUser" :rules="rules" ref="ruleForm">
        <el-form-item prop="reason">
          <div style="margin-left: 20px">
            支付金额：<span style="font-size: 25px; color: #fa8919">¥</span
            ><span style="font-size: 23px; color: #fa8919">{{
              payOrderInfo.payPrice
            }}</span>
          </div>
        </el-form-item>
        <el-form-item prop="reason">
          <div class="subItem">
            <div class="zhiFuItem">
              <div
                :class="['change-paytype', { current: payType === 'wxpay' }]"
                @click="selectPayType('wxpay')"
              >
                <div class="change-paytype-icon">
                  <img src="@/assets/imgs/wxpay.png" class="paytype-icon" />
                </div>
                <div class="change-paytype-info">
                  <div class="change-paytype-label">微信支付</div>
                </div>
              </div>
              <div
                :class="['change-paytype', { current: payType === 'alipay' }]"
                @click="selectPayType('alipay')"
              >
                <div class="change-paytype-icon">
                  <img src="@/assets/imgs/alipay.png" class="paytype-icon" />
                </div>
                <div class="change-paytype-info">
                  <div class="change-paytype-label">支付宝</div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            style="
              background-color: #fa8919;
              color: #fff;
              width: 60%;
              margin-left: 20%;
            "
            @click="handleCentain()"
            >立即支付</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="codeDialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog('codeDialogVisible')"
      width="350px"
      center
      title="使用微信扫码支付"
    >
      <qriously :value="codeUrl" :size="300" />
    </el-dialog>
  </div>
</template>
<script>
import OrderDetails from './OrderDetails'
import Pagination from '@/components/Pagination'
import { getwxShare } from '@/utils/wexinShare'
import {
  getOrderList,
  cancelOrder,
  updateUserInfo,
  weixinPay,
  alipayH5,
  alipayPc,
  hilarity,
  queryOrderStatus,
  handleRefund,
} from '@/api/home'
import { mapActions } from 'vuex'
let timer = null
export default {
  components: {
    OrderDetails,
    Pagination,
  },
  data() {
    return {
      // 支付订单信息
      activeName: 'all',
      payOrderInfo: {},
      // 二维码显隐
      codeDialogVisible: false,
      // 支付弹窗
      choosePayType: false,
      payType: 'wxpay',
      imgList: [],
      orders: [], // 订单列表
      ordersRenew: [], //续费订单列表
      total: [], // 每个订单的商品数量及总价列表
      codeUrl: '',
      codeDialogVisibleXufei: false,
      showShop: false,
      isLoading: false,
      LoginUser: {
        reason: '',
        orderId: '',
      },
      orderId: '',
      isView: false, //是否展示详情
      rules: {
        reason: [
          { required: true, message: '请填写退款原因', trigger: 'blur' },
        ],
      },
      pageInfo: {
        page: 1,
        limit: 10,
        pageSizeArea: [10, 20, 30, 50, 100],
        total: 1,
      },
      payStatusOptions: [
        {
          value: 0,
          label: '待付款',
        },
        {
          value: 1,
          label: '已付款',
        },
        {
          value: 4,
          label: '已取消',
        },
        {
          value: 5,
          label: '退款中',
        },
        {
          value: 6,
          label: '退款成功',
        },
        {
          value: 7,
          label: '退款失败',
        },
      ],
      queryParams: {},
    }
  },
  watch: {
    // 通过订单信息，计算出每个订单的商品数量及总价
    orders: function (val) {
      let total = []
      for (let i = 0; i < val.length; i++) {
        const element = val[i]

        let totalNum = 0
        let totalPrice = 0
        for (let j = 0; j < element.length; j++) {
          const temp = element[j]
          totalNum += temp.product_num
          totalPrice += temp.product_price * temp.product_num
        }
        total.push({ totalNum, totalPrice })
      }
      this.total = total
    },
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.type == '2') {
          this.getOrders()
          this.updateUserInfo()
          this.isView = false
        }
      },
    },
  },
  directives: {
    Countdown: {
      bind(el, binding) {
        const targetTime = new Date(binding.value).getTime()
        // 创建定时器更新倒计时
        timer = setInterval(() => {
          const currentTime = new Date().getTime()
          const remainingTime = Math.max(targetTime - currentTime, 0)
          const minutes = Math.floor(remainingTime / (1000 * 60))
          const seconds = Math.floor((remainingTime / 1000) % 60)

          // 更新表格单元格的内容
          el.innerHTML = `${minutes} 分 ${seconds} 秒`

          // 当倒计时结束时清除定时器
          if (remainingTime === 0) {
            clearInterval(timer)
          }
        }, 1000)
      },
      unbind() {
        // 解绑时清除定时器
        clearInterval(timer)
      },
    },
  },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.user.user,
    // }),
    userInfo: {
      get() {
        return this.$store.state.user.user
      },
      set(value) {
        this.$store.commit('setUser', value)
      },
    },
  },
  filters: {
    handleOrderStatus(value) {
      const statusMap = {
        0: '未支付',
        3: '已关闭',
        4: '已取消',
        5: '退款中',
        6: '已退款',
        7: '退款失败',
      }
      return statusMap[value]
    },
  },
  mounted(){
    this.initWeChatConfig()
  },
  methods: {
    ...mapActions(['setUser']),
    async initWeChatConfig() {
      var url = window.location.href.split('#')[0].split('?')[0];
      // const parsedURL = new URL(url);
      // const domain = parsedURL.origin;
      var goodsType = 3
      var params={
        url:url,
        goodsId:3,
        goodsType:goodsType
      }
    const path ='#/home';
     getwxShare(params,url,goodsType,path,'我的订单')
    },
    checkresetDetail(item) {
      console.log('item', item)
      this.$router.push({
        path: '/goods/resetdetails',
        query: {
          orderId: item.orderId,
        },
      })
    },
    async hilarity(row) {
      //秒杀倒计时重启
      const res = await hilarity(row.orderId)
      if (res.status === 200) {
        this.getOrders()
      }
      this.isLoading = false
    },
    handleClick(tab) {
      const paid = tab.name === 'all' ? '' : Number(tab.name)
      this.getOrders(paid)
    },
    countdownValue(createTime) {
      const dateObj = new Date(createTime)
      // 获取时间戳
      const timestamp = dateObj.getTime()
      let date = new Date(timestamp).getTime() + 30 * 1000 * 60 //返回时间加三十分钟
      return date
    },
    closeDialog(type) {
      // console.log("close.................");
      this[type] = false
      // console.log("清除定时器");
      clearInterval(this.timer)
    },
    // 判断是否在微信内置浏览器打开
    isWX() {
      var wxD = window.navigator.userAgent.toLowerCase()
      console.log(wxD)
      if (wxD.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    async handleCentain() {
      this.payBtnDisabled = true
      if (this.payType === 'wxpay') {
        const params = {
          orderId: this.payOrderInfo.orderId,
          goodsId: this.payOrderInfo.goodsId,
          phoneNumber: this.$store.getters.getUser.mobile,
          // mobilePay: this.$device.mobile ? 1 : 0,
          payType: '0',
          sellType: this.payOrderInfo.productType,
          mobilePay: this.isWX() == true ? 2 : this.$device.mobile ? 1 : 0,
          seckillId: this.payOrderInfo.seckillId
            ? this.payOrderInfo.seckillId
            : '',
        }
        console.log(params)
        const res = await weixinPay(params)
        if (res.code === 0) {
          console.log(res)

          if (this.$device.mobile && res.data.paySign && this.isWX() == true) {
            window.wx.config({
              appId: res.data.appId,
              timestamp: res.data.timeStamp,
              paySign: res.data.paySign,
              nonceStr: res.data.nonceStr,
              signature: res.data.signature,
            })
            window.wx.chooseWXPay({
              appId: res.data.appId,
              timestamp: res.data.timeStamp,
              nonceStr: res.data.nonceStr,
              package: res.data.package,
              signType: res.data.signType,
              paySign: res.data.paySign,
              success: (res) => {
                // 支付成功后的处理
                console.log('支付成功', res)
                //查询订单是否支付成功
                this.queryOrderStatus(this.payOrderInfo.orderId)
              },
              fail: (res) => {
                // 支付失败后的处理
                console.error('支付失败', res)
              },
            })
          } else if (this.$device.mobile) {
            console.log(res.data.codeUrl)
            window.location.href =
              res.data.codeUrl +
              '&redirect_url=' +
              encodeURIComponent(window.location.href)
            ;(function () {
              // 解析 URL 参数，获取要执行的方法名
              const urlParams = new URLSearchParams(window.location.search)
              const methodName = urlParams.get('method')

              // 调用特定的方法
              if (methodName === 'doSomething') {
                this.queryOrderStatus(this.orderId)
              }
            })()
          } else {
            this.orderId = res.data.orderId
            this.codeUrl = res.data.codeUrl
            //打开二维码弹窗
            this.codeDialogVisible = true
            //启动定时器
            this.timer = setInterval(() => {
              //查询订单是否支付成功
              this.queryOrderStatus(this.orderId)
            }, 3000)
          }
          //在跳转支付链接后，拼接微信回跳地址
          // location.href = res.data.codeUrl + '&redirect_url=' + encodeURIComponent(window.location.href);
        }
      }
      if (this.payType === 'alipay') {
        const params = {
          orderId: this.orderId,
          // goodsId: this.goodsDetailsList.id,
          goodsId: this.payOrderInfo.goodsId,
          phoneNumber: this.$store.getters.getUser.mobile,
          payType: '1',
          sellType: this.payOrderInfo.productType,
          seckillId: this.payOrderInfo.seckillId
            ? this.payOrderInfo.seckillId
            : '',
        }
        if (this.$device.mobile) {
          const res = await alipayH5(params)
          if (res.code == 0) {
            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.data.formStr
            document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit
            document.querySelector('#alipay form').submit()

            // 清除添加的 div 元素
            document.body.removeChild(div)
          } else {
            this.payBtnDisabled = false
          }
        } else {
          //pc
          const res = await alipayPc(params)
          if (res.code == 0) {
            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.data.formStr
            document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit()
            document.querySelector('#alipay form').submit()

            // 清除添加的 div 元素
            document.body.removeChild(div)
          } else {
            this.payBtnDisabled = false
          }
        }
      }
    },
    // 查询订单状态
    async queryOrderStatus(orderId) {
      const res = await queryOrderStatus(orderId)
      if (res.code == 0) {
        clearInterval(this.timer)
        this.codeDialogVisible = false
        this.choosePayType = false
        this.notifySucceed('支付成功')
        this.getOrders()
        this.updateUserInfo()
      }
    },
    //  更新用户信息
    async updateUserInfo() {
      // console.log(localStorage.getItem('user'));
      const phone = JSON.parse(this.userInfo.mobile)
      const res = await updateUserInfo(phone)
      if (res.code == 0) {
        const userInfo = res.data.loginUser
        this.userInfo = userInfo
      }
    },
    selectPayType(type) {
      // console.log("支付方式：" + type);
      this.payType = type
      //this.$router.push({ path: '/order' })
    },
    goPay(row) {
      this.choosePayType = true
      this.payOrderInfo = row
    },
    handleTimeShow(date) {
      // 获取当前时间的时间戳
      const currentTimestamp = new Date().getTime()

      // 假设给定的时间是 "2023-09-22 09:20:00"
      const targetTime = new Date(date)
      const targetTimestamp = targetTime.getTime()

      // 计算时间戳的差值
      const timeDiff = Math.abs(targetTimestamp - currentTimestamp)
      // console.log('timeDiff', timeDiff);
      // 判断时间间隔是否为30分钟
      const is30Minutes = timeDiff <= 30 * 60 * 1000
      // console.log('is30Minutes', is30Minutes);
      return is30Minutes
    },
    handleTime() {
      var targetTime = new Date('2023-09-22 21:41:10')
      targetTime.setMinutes(targetTime.getMinutes() + 30) // 加上30分钟的毫秒数

      // 计算倒计时时间
      var timeDiff = Math.max(targetTime.getTime() - new Date().getTime(), 0) // 确保倒计时不会出现负数
      var minutes = Math.floor(timeDiff / (1000 * 60))
      var seconds = Math.floor((timeDiff / 1000) % 60)

      // 返回倒计时时间
      return {
        minutes: minutes,
        seconds: seconds,
      }
    },
    handleSizeChange(val) {
      this.pageInfo.limit = val
      this.getOrders()
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val
      this.getOrders()
    },
    // 跳转到商品详情
    handleLinkDetail(row) {
      const { isUse, productType, policyId } = row
      if (isUse === 0) {
        this.$message.warning('商品已下架，无法查看商品详情！')
        return
      } else {
        if (productType === 3) {
          this.$message.warning('当前订单为升级订单，无法查看商品详情！')
          return
        } else if (productType === 2) {
          this.$message.warning('当前订单为内存扩容订单，无法查看商品详情！')
          return
        } else {
          this.$router.push({
            path: '/goods/details',
            query: { productID: policyId },
          })
        }
      }
    },
    handleQueryOrder() {
      this.getOrders()
    },
    handleReset() {
      this.queryParams = {}
      this.getOrders(this.activeName)
    },
    //取消订单
    async cancellationClick(row) {
      try {
        await this.$confirm('此操作将永久删除该改订单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })

        const res = await cancelOrder(row.orderId)
        // console.log(res);
        if (res.code == 0) {
          this.getOrders()
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      }
    },
    // 查看订单
    checkOrderDetail(rowData, type) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      if (type == 'h5') {
        localStorage.setItem('orderInfo', JSON.stringify(rowData))
        this.$router.push('MOrderDetail')
        return
      }
      // this.isView = true
      this.$emit('isDetail', true, rowData)
      console.log('orderDetailData', rowData)
      this.orderDetailData = rowData
    },
    async getOrders(paid) {
      this.isLoading = true
      const { page, limit } = this.pageInfo
      const params = {
        phoneNumber: this.$store.getters.getUser.mobile,
        page,
        limit,
        paid,
      }
      if (Object.keys(this.queryParams).length !== 0) {
        Object.assign(params, this.queryParams)
      }
      // 获取订单列表
      const res = await getOrderList(params)
      // console.log(res);
      if (res.code === 0) {
        const { list, total } = res.data.data
        console.log(list)
        this.orders = list
        // this.imgList =list!==''? list[0].goodsImg.split(",")[0]:''
        this.pageInfo.total = total
      }
      this.isLoading = false
    },
    updateIsView(val) {
      this.isView = val
    },
    deatilClick(item) {
      this.$router.push({
        path: '/goods/details',
        query: {
          productID: item.policyId,
        },
      })
    },
    xufeiDetailsClick(item) {
      this.$router.push({
        path: '/goods/details',
        query: {
          productID: item.goodsId,
          isXufei: '1',
        },
      })
    },
    internalStorageDetailsClick(row) {
      this.$router.push({
        path: '/goods/internalStorageDetails',
        query: {
          extensionOrderId: row.orderId,
        },
      })
    },
    // 退款弹窗
    refundClick(item) {
      // console.log(item);
      this.codeDialogVisible = true
      this.LoginUser.orderId = item.orderId
    },
    refundClickXufei(item) {
      this.codeDialogVisibleXufei = true
      this.LoginUser.orderId = item.orderId
    },
    // 正常订单退款申请
    async handleRefund() {
      const res = await handleRefund(
        this.LoginUser.orderId,
        this.LoginUser.reason
      )
      console.log(res)
      if (res.code == 0) {
        this.notifySucceed(res.message)
        this.codeDialogVisible = false
        this.getOrders()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.opt-one-btn {
  margin-bottom: 10px;
}
.positImg {
  // position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.swatch-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
// 遮罩层
.out-of-stock-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  // background-color: red;
  // display: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
// 遮罩层
::v-deep .el-button--small {
  font-size: 0.9rem !important;
}
::v-deep .el-table tr {
  font-size: 0.9rem !important;
}
.subItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  // background-color: red;
  .itemTitle {
    width: 15%;
    font-size: 16px;
    color: #666;
  }

  .itemContent {
    width: 80%;
  }

  ul {
    display: flex;
    align-items: s;
    flex-wrap: wrap;
    width: 100%;

    li {
      font-size: 18px;
      padding: 5px 20px;
      border-radius: 5px;
      border: 1px solid #e7ecf0;
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    li:hover {
      cursor: pointer;
      border: 1px solid #fa8919 !important;
      color: #fa8919;
    }

    .selectActive {
      border: 1px solid #fa8919 !important;
      color: #fa8919;
    }
  }

  // 选择支付方式
  .zhiFuItem {
    display: flex;

    /* 选择支付方式 */
    .change-paytype {
      position: relative;
      display: flex;
      align-items: center;
      width: 207px;
      height: 58px;
      margin-right: 10px;
      border: 1px solid #e7ecf0;
      border-radius: 4px;
      cursor: pointer;
    }

    .change-paytype.current {
      border-color: #fa8919;
      color: #fa8919;
    }

    .change-paytype.current:after {
      content: '';
      display: block;
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
        no-repeat;
      background-size: 28px 28px;
    }

    .change-paytype-icon {
      margin: 0 11px 0 15px;
    }

    .paytype-icon {
      display: block;
      width: 24px;
      height: 24px;
    }

    .change-paytype-info {
      position: relative;
      flex: 1;
    }

    .change-paytype:hover {
      border-color: #fa8919;
    }

    .change-paytype-label:hover {
      color: #fa8919;
    }
  }

  // 价格
  .price-info {
    width: 80%;
    display: flex;
    align-items: self-end;
    flex-wrap: wrap;
    color: #ff4302;
    margin-top: 20px;

    .sale-price {
      display: flex;
      align-items: self-end;
    }

    .price-icon {
      font-size: 30px;
      font-weight: 400;
      margin-right: 10px;
    }

    .price-val {
      font-size: 45px;
      font-weight: bold;
    }

    .price-old {
      color: #a1a1a1;
      font-size: 25px;
      margin-left: 20px;
    }
  }
}

.back-btn {
  display: none;
}

.page-box {
  width: 100%;

  .order-infobox {
    .box-card {
      margin-bottom: 15px;
      border-radius: 12px;

      .order-table {
        margin: 0 auto;
        border-radius: 12px;
        font-size: 14px !important;
        border: 1px solid #ccc;
      }
    }

    .el-dialog__wrapper {
      background: red;
    }
  }
}

.app-order-info {
  display: none;
}

/* .order-infobox {
  display: flex;
  justify-content: space-between;
} */
// .box-card {
//   margin-bottom: 15px;
//   border-radius: 12px;
// }
// .user-txt-box {
//   p {
//     margin-bottom: 10px;
//   }
// }
// .order-table {
//   margin: 0 auto;
//   border-radius: 12px;
//   border: 1px solid #ccc;
// }
@media only screen and (max-width: 758px) {
  .customWidth {
    width: 400px;
  }

  .zhiFuItem {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;

    /* 选择支付方式 */
    .change-paytype {
      position: relative;
      display: flex;
      align-items: center;
      width: 207px;
      height: 58px;
      margin-right: 10px;
      border: 1px solid #e7ecf0;
      border-radius: 4px;
      cursor: pointer;
    }

    .change-paytype.current {
      border-color: #fa8919;
      color: #fa8919;
    }

    .change-paytype.current:after {
      content: '';
      display: block;
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
        no-repeat;
      background-size: 28px 28px;
    }

    .change-paytype-icon {
      margin: 0 11px 0 15px;
    }

    .paytype-icon {
      display: block;
      width: 24px;
      height: 24px;
    }

    .change-paytype-info {
      position: relative;
      flex: 1;
    }

    .change-paytype:hover {
      border-color: #fa8919;
    }

    .change-paytype-label:hover {
      color: #fa8919;
    }
  }

  .back-btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 20px;

    img {
      width: 30px;
    }
  }

  .user-tit {
    color: #fa8919;
    margin-left: 5px;
    font-size: 20px;
  }

  .page-box {
    // background-color: #FFF;
    width: 93%;
    // min-height: 68vh;
    margin: 0 auto;
    margin-top: 20px;
    padding-top: 1px;
  }

  .order-infobox {
    display: none;
  }

  .app-order-info {
    display: block;

    .el-form {
      background-color: #fff;
    }

    .demo-form-inline {
      padding: 10px;
    }

    .app-goodorder-box {
      margin-top: 15px;
    }

    .price-icon {
      font-size: 20px;
      margin-right: 5px;
    }

    .app-goodorder-item {
      background-color: #fff;
      border-radius: 6px;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      .orderid-box {
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
      }

      .app-good-info {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        padding-bottom: 15px;
      }

      .app-good-img {
        width: 130px;
        height: 100px;
      }

      .app-good-opt {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .el-button--primary {
          border: 1px solid #fa8919;
          color: #fa8919;
          background-color: initial;
        }
      }

      .good-txt-box {
        width: 80%;
        margin-left: 30px;
        text-align: right;

        .good-payprice {
          color: #fa8919;
          font-size: 30px;
          margin: 5px 0;
        }

        .good-original-price {
          color: #888;
        }

        .order-status {
          color: #666;
          margin-top: 10px;
        }
      }
    }
  }
}

.order-goodimg {
  width: 170px;
  height: 120px;
  object-fit: contain;
}

// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .el-statistic {
    .con {
      .number {
        margin-right: -50px !important;
      }
    }
  }

  .customWidth {
    width: 400px;
  }

  .zhiFuItem {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;

    /* 选择支付方式 */
    .change-paytype {
      position: relative;
      display: flex;
      align-items: center;
      width: 207px;
      height: 58px;
      margin-right: 10px;
      border: 1px solid #e7ecf0;
      border-radius: 4px;
      cursor: pointer;
    }

    .change-paytype.current {
      border-color: #fa8919;
      color: #fa8919;
    }

    .change-paytype.current:after {
      content: '';
      display: block;
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
        no-repeat;
      background-size: 28px 28px;
    }

    .change-paytype-icon {
      margin: 0 11px 0 15px;
    }

    .paytype-icon {
      display: block;
      width: 24px;
      height: 24px;
    }

    .change-paytype-info {
      position: relative;
      flex: 1;
    }

    .change-paytype:hover {
      border-color: #fa8919;
    }

    .change-paytype-label:hover {
      color: #fa8919;
    }
  }

  .back-btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 20px;

    img {
      width: 30px;
    }
  }

  .user-tit {
    color: #fa8919;
    margin-left: 5px;
    font-size: 20px;
  }

  .page-box {
    // background-color: #FFF;
    width: 93%;
    // min-height: 68vh;
    margin: 0 auto;
    margin-top: 20px;
    padding-top: 1px;
  }

  .order-infobox {
    display: none;
  }

  .app-order-info {
    display: block;

    .el-form {
      background-color: #fff;
    }

    .demo-form-inline {
      padding: 10px;
    }

    .app-goodorder-box {
      margin-top: 15px;
    }

    .price-icon {
      font-size: 20px;
      margin-right: 5px;
    }

    .app-goodorder-item {
      background-color: #fff;
      border-radius: 6px;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      .orderid-box {
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
      }

      .app-good-info {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        padding-bottom: 15px;
      }

      .app-good-img {
        width: 130px;
        height: 100px;
      }

      .app-good-opt {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-top: 20px;

        .el-button--primary {
          border: 1px solid #fa8919;
          color: #fa8919;
          background-color: initial;
          margin-bottom: 5px;
        }
      }

      .good-txt-box {
        width: 80%;
        margin-left: 30px;
        text-align: right;

        .good-payprice {
          color: #fa8919;
          font-size: 30px;
          margin: 5px 0;
        }

        .good-original-price {
          color: #888;
        }

        .order-status {
          color: #666;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
<style>
.order-infobox .el-statistic {
  width: auto;
  margin-left: 5px;
}
</style>
